
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SettingsItem",
  props: {
    icon: String,
    text: String,
    withOutBorder: Boolean,
    borderRadius: String,
  },
  setup(props) {
    const { t } = useI18n();
    const border = computed(() => ({
      border__radius__none: props.borderRadius === "none",
      border__radius__top: props.borderRadius === "top",
      border__radius__bottom: props.borderRadius === "bottom",
    }));
    return { t, border };
  },
});
