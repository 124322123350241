
import { defineComponent, ref } from "vue";
import { ROUTES } from "@/constants/routes.constants";
import { useI18n } from "vue-i18n";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.store";
import MenuUserProfile from "@/components/baseLayout/MenuUserProfile.vue";
import { isPublic, routePublicPath } from "@/service/public/index.service";
import router from "@/router";

export default defineComponent({
  name: "MainMenu",
  components: { MenuUserProfile },
  props: {
    isOpen: Boolean,
  },
  emits: ["update:isOpen"],
  setup() {
    const { t } = useI18n();
    const test = ref<HTMLHeadingElement>();
    const currentLayout = router.currentRoute.value.meta.layout;
    const appStore = useAppStateStore();
    const userStore = useUserStore();
    const { user, userNftCount } = storeToRefs(userStore);
    const { isMobile } = storeToRefs(appStore);
    const visiblePopup = (registred: boolean) => {
      if (!registred) {
        test.value!.style.visibility = "visible";
        test.value!.style.opacity = "1";
        test.value!.style.marginBottom = "36px";
      }
    };
    const unvisiblePopup = (registred: boolean) => {
      if (!registred) {
        test.value!.style.visibility = "hidden";
        test.value!.style.opacity = "0";
        test.value!.style.marginBottom = "0px";
      }
    };
    const links = [
      {
        to: ROUTES.MY_NFTS.path,
        name: "myNft",
        events: isPublic() ? false : true,
      },
      {
        to: routePublicPath(currentLayout, "COLLECTIONS"),
        name: "collections",
        events: true,
      },
      {
        to: routePublicPath(currentLayout, "DROPS"),
        name: "drops",
        events: true,
      },
      {
        to: routePublicPath(currentLayout, "PERKS"),
        name: "perks",
        events: true,
      },
    ];

    return {
      t,
      isMobile,
      links,
      user,
      test,
      isPublic,
      routePublicPath,
      userNftCount,
      visiblePopup,
      unvisiblePopup,
      ROUTES,
    };
  },
});
