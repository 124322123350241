
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseBurger",
  props: {
    isOpen: Boolean,
  },
  emits: ["update:isOpen"],
  setup() {
    return {};
  },
});
