import { withModifiers as _withModifiers, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closeModal()), ["stop","self"])),
        id: "push",
        class: _normalizeClass(["popup flex justify-center", { transfer__popup: _ctx.route.name === _ctx.ROUTES.MY_NFT.name }]),
        style: _normalizeStyle({ background: _ctx.pushBg })
      }, null, 6), [
        [_vShow, _ctx.showPushPopup]
      ])
    ]),
    _: 1
  }))
}