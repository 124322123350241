
import { defineComponent } from "vue";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";
import { ROUTES } from "@/constants/routes.constants";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "BasePopup",
  setup() {
    const route = useRoute();
    const appStateStore = useAppStateStore();
    const closeModal = () => {
      appStateStore.setIsShowModal(false);
    };
    const appStore = useAppStateStore();
    const { showModal } = storeToRefs(appStore);
    const { modalBg } = storeToRefs(appStore);
    return { closeModal, showModal, ROUTES, route, modalBg };
  },
});
