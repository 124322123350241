
import { computed, defineComponent, PropType } from "vue";
import SettingsItem from "@/components/common/SettingsItem.vue";
import SettingsItemArrow from "@/components/common/SettingsItemArrow.vue";

export default defineComponent({
  name: "SettingsLink",
  components: { SettingsItemArrow, SettingsItem },
  props: {
    to: [String, Object, undefined] as PropType<
      string | undefined | { name: string }
    >,
    icon: String,
    text: String,
    withOutBorder: Boolean,
    borderRadius: String,
  },
  setup(props) {
    const border = computed(() => ({
      border__radius__none: props.borderRadius === "none",
      border__radius__top: props.borderRadius === "top",
      border__radius__bottom: props.borderRadius === "bottom",
    }));
    const currentComponent = props.to ? "RouterLink" : "button";

    return { border, currentComponent };
  },
});
