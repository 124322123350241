import instance from "@/service/api/api.instance";
import { useUserStore } from "@/store/user.store";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@/constants/constants";
import { PostRefresh } from "@/service/auth/auth.type";
import firebase from "firebase";
import { checkUserState } from "@/utils/middlewares/userState.middleware";
import { getCurrentUser } from "@/service/user/user.service";

export const checkAuthUser = async (): Promise<boolean> => {
  try {
    const token = localStorage.getItem(ACCESS_TOKEN);
    return token
      ? await getCurrentUser().then((user) => +user.state > 0)
      : false;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCodeByPhone = async (phone: string) => {
  try {
    await instance.post("/auth/login/phone", {
      phone,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const putResetPasswordByEmail = async (email: string) => {
  try {
    await instance.put("/user/resetPassword", { email });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const putMarketingSms = async (
  receiveMarketingSms: boolean,
  userId: number,
) => {
  try {
    await instance.put(`/user/${userId}`, { receiveMarketingSms });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const putResetPasswordByPhone = async (phone: string) => {
  try {
    await instance.put("/user/resetPassword/phone", { phone });
  } catch (e) {
    return Promise.reject(e);
  }
};
export const postRefreshToken = async (
  refreshToken: string,
): Promise<PostRefresh> => {
  try {
    const res = await instance.post("/auth/refresh-token", { refreshToken });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const loginByEmail = async (email: string, password: string) => {
  try {
    const user = await instance.post("/auth/login", { email, password });
    const userStore = useUserStore();
    userStore.setUser(user.data);
    localStorage.setItem(ACCESS_TOKEN, user.data.accessToken);
    localStorage.setItem(REFRESH_TOKEN, user.data.refreshToken);
    await checkUserState(user.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const verifyPhone = async (phone: string, userId: number) => {
  try {
    await instance.put(`/user/${userId}/verifyPhone`, { phone });
  } catch (e) {
    await Promise.reject(e);
  }
};
export const verifyCodeByPhone = async (phone: string, code: string) => {
  try {
    const user = await instance.post("/auth/login/phone/check-code", {
      phone,
      code,
    });
    const userStore = useUserStore();
    userStore.setUser(user.data);
    localStorage.setItem(ACCESS_TOKEN, user.data.accessToken);
    localStorage.setItem(REFRESH_TOKEN, user.data.refreshToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const logOut = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  const userStore = useUserStore();
  userStore.$reset();
};

export const registrationByEmail = async (email: string, pass: string) => {
  try {
    await instance.post("/user/register", {
      email,
      password: pass,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const sendCodeOnVerifyNumber = async (
  phoneValue: string,
  recaptchaVerifier: any,
) => {
  try {
    const auth = firebase.auth();
    const provider = new firebase.auth.PhoneAuthProvider(auth);
    const verificationId = await provider.verifyPhoneNumber(
      `+${phoneValue}`,
      recaptchaVerifier,
    );
    return verificationId;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const recaptchaVerify = async () => {
  const container = document.getElementById("recaptcha-container");
  if (container) {
    container.innerHTML = "";
  }
  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
      callback: async () => {
        await sendCodeOnVerifyNumber;
      },
      "expired-callback": () => {
        console.log("reCAPTCHA expired");
      },
      siteKey: process.env.VUE_APP_RECAPTCHA,
    },
  );
  if (recaptchaVerifier) {
    recaptchaVerifier
      .render()
      .then(() => {
        console.log(1);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  return recaptchaVerifier;
};
