import { User } from "@/service/user/user.types";
import { ROUTES } from "@/constants/routes.constants";
import router from "@/router";
import { QrStepReg } from "@/service/auth/auth.enum";

export const checkUserState = async (user: User) => {
  const isQr = window.location.href.includes("qr");
  if (isQr && user.state === "0") {
    return user.state === "0";
    return { type: "reg", step: QrStepReg.phone };
  }
  return user.state === "0"
    ? await router.push({ name: ROUTES.REG_PHONE_NUMBER.name })
    : user.state === "0";
};
