import { defineStore } from "pinia";
import { ref } from "vue";

export const useAppStateStore = defineStore("appState", () => {
  const preloader = ref(true);
  const setPreloaderValue = (value: boolean) => (preloader.value = value);

  const isMobile = ref(false);
  const setIsMobile = (value: boolean) => (isMobile.value = value);

  const showModal = ref(false);
  const setIsShowModal = (value: boolean) => (showModal.value = value);

  const showPushPopup = ref(false);
  const setShowPushPopup = (value: boolean) => (showPushPopup.value = value);

  const modalBg = ref("");
  const setIsModalBg = (value: string) => (modalBg.value = value);
  const pushBg = ref("");
  const setIsPushBg = (value: string) => (pushBg.value = value);

  return {
    preloader,
    isMobile,
    showModal,
    modalBg,
    showPushPopup,
    pushBg,
    setIsPushBg,
    setShowPushPopup,
    setPreloaderValue,
    setIsMobile,
    setIsShowModal,
    setIsModalBg,
  };
});
