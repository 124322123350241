import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsItemArrow = _resolveComponent("SettingsItemArrow")!
  const _component_SettingsItem = _resolveComponent("SettingsItem")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
    class: "link",
    to: _ctx.to
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsItem, {
        icon: _ctx.icon,
        text: _ctx.text,
        "with-out-border": _ctx.withOutBorder,
        "border-radius": _ctx.borderRadius
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SettingsItemArrow)
        ]),
        _: 1
      }, 8, ["icon", "text", "with-out-border", "border-radius"])
    ]),
    _: 1
  }, 8, ["to"]))
}