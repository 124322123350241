import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { resetStorePlugin } from "@/store/plugins/reset";
import i18n from "./utils/i18n";
import { clickOutside } from "@/utils/clickOutside";
import { VueDapp } from "vue-dapp";
import firebase from "firebase";
import "./utils/prost";

const pinia = createPinia();
pinia.use(resetStorePlugin);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n)
  .use(VueDapp)
  .directive("click-outside", clickOutside)
  .mount("#app");
