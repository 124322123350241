export enum LoginEnum {
  invalid,
  phone,
  email,
}

export enum QrMountedEnum {
  undefined,
  unAuth,
  auth,
}

export enum QrStepLogin {
  login = 1,
  verify,
  loginDone,
  resetPass,
  resetPassDone,
}

export enum QrStepReg {
  reg = 1,
  phone,
  verify,
  regDone,
}
