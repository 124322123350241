
import { defineComponent } from "vue";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "BaseLayout",
  components: {},
  setup() {
    const appState = useAppStateStore();
    const { preloader } = storeToRefs(appState);
    return { preloader };
  },
});
