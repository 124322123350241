
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LocaleSwitcher",

  setup() {
    const { locale } = useI18n();
    const locales = {
      en: "Eng",
      es: "Es",
    };
    const isOpen = ref(false);
    const localesArray = Object.entries(locales);
    const currentLocale = computed(() =>
      localesArray.find((item) => item[0] === locale.value),
    );

    watch(locale, (newCurrentLocale) =>
      localStorage.setItem("locale", newCurrentLocale),
    );

    onBeforeMount(
      () => (locale.value = localStorage.getItem("locale") || "en"),
    );
    const getLocaleList = computed(() =>
      localesArray.filter((item) => item[0] !== locale.value),
    );
    const closeLocaleList = () => {
      isOpen.value = false;
    };
    return {
      locale,
      locales,
      currentLocale,
      isOpen,
      getLocaleList,
      closeLocaleList,
    };
  },
});
