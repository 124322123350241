export const ROUTES = {
  HOME: {
    path: "/",
    name: "home",
  },
  LOGIN: {
    path: "/login",
    name: "login",
  },
  LOGIN_PHONE_VERIFY: {
    path: "/login/verify",
    name: "loginVerify",
  },
  RESET_PASSWORD: {
    path: "/resetPassword",
    name: "resetPasswordView",
  },
  REG_PHONE_VERIFY: {
    path: "/registration/phone/verify",
    name: "regVerify",
  },
  MY_NFTS: {
    path: "/my-nft",
    name: "myNfts",
  },
  MY_NFT: {
    path: "/my-nft/:id",
    name: "myNft",
  },
  COLLECTIONS: {
    path: "/collections",
    name: "collections",
  },
  PUBLIC_COLLECTIONS: {
    path: "/public/collections",
    name: "publicCollections",
  },
  COLLECTION: {
    path: "/collection/:id",
    name: "collection",
  },
  PUBLIC_COLLECTION: {
    path: "/public/collection/:id",
    name: "publicCollection",
  },
  DROP: {
    path: "/collection/:collectionId/drop/:id",
    name: "drop",
  },
  PUBLIC_DROP: {
    path: "/public/collection/:collectionId/drop/:id",
    name: "publicDrop",
  },
  MINT: {
    path: "/collection/:collectionId/drop/:id/mint",
    name: "mint",
  },
  PUBLIC_MINT: {
    path: "/public/collection/:collectionId/drop/:id/mint",
    name: "publicMint",
  },
  DROPS: {
    path: "/drops",
    name: "drops",
  },
  PUBLIC_DROPS: {
    path: "/public/drops",
    name: "publicDrops",
  },
  PUBLIC_PERKS: {
    path: "/public/perks",
    name: "publicPerks",
  },
  PERKS: {
    path: "/perks",
    name: "perks",
  },
  PERK: {
    path: "/perks/:id",
    name: "perk",
  },
  PUBLIC_PERK: {
    path: "/public/perks/:id",
    name: "publicPerk",
  },
  USER_SETTINGS: {
    path: "/user-settings",
    name: "userSettings",
  },
  USER_SETTINGS_WALLET: {
    path: "wallet",
    name: "userSettingsWallet",
  },
  USER_SETTINGS_FAQ: {
    path: "faq",
    name: "userSettingsFaq",
  },
  USER_SETTINGS_REFERRAL: {
    path: "referral",
    name: "userSettingsReferral",
  },
  REGISTRATION: {
    path: "/registration",
    name: "registration",
  },
  REG_PHONE_NUMBER: {
    path: "/registration/phone",
    name: "regPhoneNumber",
  },
  QR_VIEW: {
    path: "/qr/:id",
    name: "qrView",
  },
};
