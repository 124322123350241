import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "wrapper flex flexCenter",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.closeModal()), ["stop","self"]))
      }, [
        _createElementVNode("div", {
          id: "modal",
          class: _normalizeClass(["popup flex justify-center", { transfer__popup: _ctx.route.name === _ctx.ROUTES.MY_NFT.name }]),
          style: _normalizeStyle({ background: _ctx.modalBg })
        }, [
          _createElementVNode("span", {
            class: "popup__cancel",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closeModal()), ["self"]))
          })
        ], 6)
      ], 512), [
        [_vShow, _ctx.showModal]
      ])
    ]),
    _: 1
  }))
}