
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.store";
import { useRoute, useRouter } from "vue-router";
import { ROUTES } from "@/constants/routes.constants";
import BasePreloader from "@/components/baseLayout/BasePreloader.vue";
import LocaleSwitcher from "@/components/baseLayout/LocaleSwitcher.vue";
import MainMenu from "@/components/baseLayout/MainMenu.vue";
import BaseBurger from "@/components/baseLayout/BaseBurger.vue";

export default defineComponent({
  name: "PublicLayout",
  components: {
    BasePreloader,
    LocaleSwitcher,
    MainMenu,
    BaseBurger,
  },
  setup() {
    const { t } = useI18n();
    const appStore = useAppStateStore();
    const userStore = useUserStore();
    const route = useRoute();
    const { isMobile } = storeToRefs(appStore);
    const { user } = storeToRefs(userStore);
    const isOpenMenu = ref(false);
    const isOpenUserMenu = ref(false);
    const bgRightHeader = computed(
      () => useRouter().currentRoute.value.meta.bgRightHeader,
    );
    const bgAllHeader = computed(
      () => useRouter().currentRoute.value.meta.bgAllHeader,
    );
    const mobHeader = computed(
      () => useRouter().currentRoute.value.meta.mobHeader,
    );
    const headerIndex = computed(
      () => useRouter().currentRoute.value.meta.bgAllHeader,
    );
    watch(
      () => route.name,
      () => {
        isOpenMenu.value && (isOpenMenu.value = false);
        isOpenUserMenu.value && (isOpenUserMenu.value = false);
      },
    );

    return {
      t,
      isOpenMenu,
      isMobile,
      user,
      isOpenUserMenu,
      ROUTES,
      bgRightHeader,
      bgAllHeader,
      headerIndex,
      mobHeader,
    };
  },
});
