
import { defineComponent } from "vue";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";
import { ROUTES } from "@/constants/routes.constants";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "PushPopup",
  setup() {
    const route = useRoute();
    const appStateStore = useAppStateStore();
    const closeModal = () => {
      appStateStore.setShowPushPopup(false);
    };
    const appStore = useAppStateStore();
    const { showPushPopup } = storeToRefs(appStore);
    const { pushBg } = storeToRefs(appStore);
    return { closeModal, showPushPopup, ROUTES, route, pushBg, appStateStore };
  },
});
