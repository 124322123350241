import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePopup = _resolveComponent("BasePopup")!
  const _component_PushPopup = _resolveComponent("PushPopup")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BasePopup),
    _createVNode(_component_PushPopup),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.layout)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 64))
}