import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2359e626"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "item__content flex justify-between align-center" }
const _hoisted_3 = { class: "item__slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item flex align-center", [{ withOutBorder: _ctx.withOutBorder }, _ctx.border]])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        class: "item__icon",
        src: require(`@/assets/images/icons/${_ctx.icon}`),
        alt: _ctx.icon
      }, null, 8, _hoisted_1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.t(_ctx.text)) + " ", 1),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 2))
}