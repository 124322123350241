import { useRoute } from "vue-router";
import { ROUTES } from "@/constants/routes.constants";

export const isPublic = (): boolean => useRoute().meta.layout === "public";

export const routePublic = (
  layout: string | undefined,
  name: Extract<
    keyof typeof ROUTES,
    "COLLECTION" | "COLLECTIONS" | "DROP" | "MINT" | "PERK" | "PERKS"
  >,
): string | undefined =>
  layout === "public" ? ROUTES[`PUBLIC_${name}`].name : ROUTES[name].name;

export const routePublicPath = (
  layout: string | undefined,
  name: Extract<
    keyof typeof ROUTES,
    "COLLECTION" | "COLLECTIONS" | "DROP" | "MINT" | "PERK" | "PERKS" | "DROPS"
  >,
): string | undefined =>
  layout === "public" ? ROUTES[`PUBLIC_${name}`].path : ROUTES[name].path;

export const apiRoutePublic = () => {
  return useRoute().meta.layout === "public" ? "/public" : "";
};
