
import { Component, computed, defineComponent } from "vue";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import BaseLayout from "@/layouts/BaseLayout.vue";
import BasePopup from "@/components/popup/BasePopup.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";
import PushPopup from "@/components/popup/PushPopup.vue";

export default defineComponent({
  name: "App",
  components: {
    PushPopup,
    BasePopup,
    BaseLayout,
    PublicLayout,
  },
  setup() {
    const appStateStore = useAppStateStore();
    const route = useRoute();
    const { preloader } = storeToRefs(appStateStore);

    const mediaQuery = window.matchMedia("(max-width: 768px)");
    appStateStore.setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", (e: MediaQueryListEvent) =>
      appStateStore.setIsMobile(e.matches),
    );

    const layouts: Record<string, Component> = {
      base: BaseLayout,
      public: PublicLayout,
    };
    const layout = computed<Component | null>(() =>
      route.meta.layout ? layouts[route.meta.layout] : null,
    );
    return {
      preloader,
      layout,
      route,
    };
  },
});
