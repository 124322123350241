import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { User } from "@/service/user/user.types";

export const useUserStore = defineStore("userStore", () => {
  const user = reactive<User>({
    email: "",
    id: 0,
    phone: "",
    name: null,
    storeCredit: 0,
    state: "",
    wallet: {
      address: null,
      type: null,
    },
    receiveMarketingSms: false,
  });
  const setUser = ({
    phone,
    email,
    id,
    name,
    wallet,
    storeCredit,
    receiveMarketingSms,
  }: User) => {
    user.phone = phone;
    user.id = id;
    user.email = email;
    user.name = name;
    user.wallet = wallet;
    user.storeCredit = storeCredit;
    user.receiveMarketingSms = receiveMarketingSms;
  };

  const userOrders = ref([]);
  const setUserOrders = (value: []) => (userOrders.value = value);

  const userNftCount = ref(0);
  const setUserNftCount = (value: number) => (userNftCount.value = value);

  return {
    user,
    userOrders,
    userNftCount,
    setUser,
    setUserOrders,
    setUserNftCount,
  };
});
