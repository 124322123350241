
import { defineComponent, PropType } from "vue";
import { User } from "@/service/user/user.types";
import UserAvatar from "@/components/baseLayout/UserAvatar.vue";
import SettingsLink from "@/components/common/SettingsLink.vue";
import SettingsItem from "@/components/common/SettingsItem.vue";
import SettingsItemArrow from "@/components/common/SettingsItemArrow.vue";
import { ROUTES } from "@/constants/routes.constants";
import { logOut } from "@/service/auth/auth.service";
import { useRouter } from "vue-router";
import { isPublic } from "@/service/public/index.service";
import { useAppStateStore } from "@/store/appState.store";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "MenuUserProfile",
  emits: ["clickAvatar", "update:isOpen"],
  components: {
    // StoreCredits,
    SettingsItemArrow,
    SettingsItem,
    SettingsLink,
    UserAvatar,
  },
  props: {
    user: Object as PropType<User>,
    type: String as PropType<"menu" | "settings" | "desktop">,
    isOpen: Boolean,
  },
  setup(props) {
    const appStore = useAppStateStore();
    const { isMobile } = storeToRefs(appStore);
    const isDesktop = props.type === "desktop";
    const isSettings = props.type === "settings";
    const router = useRouter();
    const onLogOut = () => {
      logOut();
      router.push(ROUTES.PUBLIC_COLLECTIONS.path);
    };
    return {
      isDesktop,
      isSettings,
      ROUTES,
      onLogOut,
      isPublic,
      isMobile,
    };
  },
});
